
/* @import url("https://fonts.googleapis.com/css?family=Open+Sans:300i,400|Solway:400,500&display=swap"); */
body {
  margin: 0;
  font-family:'Courier New', Courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
