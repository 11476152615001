
body {
    font-family: "Solway", serif;
}

#navbarSupportedContent {
    // box-shadow : 1px 0px 5px rgb(119, 118, 118);
    font-size: large;
    ul {
        margin-left: 150px;
        
        li {
            margin-left: 0.6rem;
            font-weight: 500;
            a {color: black;}
        }
    } 

}
// ============================== general for selection ===================
section {
  padding: 60px 0;
}

.section-bg {
  background-color: #f6f9fd;
}

.section-title {
  padding-bottom: 40px;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #f38b74;
  margin: 4px 10px;
}

.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #0f2f57;
}




//============================= Footer ============================
#footer {
    background: #071527;
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
  }
  
  #footer .footer-top {
    padding: 60px 0 30px 0;
  }
  
  #footer .footer-top .footer-info {
    margin-bottom: 15px;
    background: #05101e;
    border-top: 4px solid #ed502e;
    text-align: center;
    padding: 30px 20px;
  }
  
  #footer .footer-top .footer-info h3 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 500;
  }
  
  #footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
  }
  
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    background: #0a1e38;
  }
  
  #footer .footer-top .social-links a:hover {
    color: #fff;
    background: #ed502e;
    text-decoration: none;
  }
  
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #ef6445;
    font-size: 18px;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    color: #ef6445;
  }
  
  #footer .footer-top .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4px;
  }
  
  #footer .footer-top .footer-newsletter form input[type="text"] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
  }
  
  #footer .footer-top .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px 2px 20px;
    background: #ed502e;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
  }
  
  #footer .footer-top .footer-newsletter form input[type="submit"]:hover {
    background: #ef6445;
  }
  
  #footer .copyright {
    border-top: 1px solid #0f2f57;
    text-align: center;
    padding-top: 30px;
  }
  
  #footer .credits  {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
    a{
        color: #ef6445;
    }
  }
  
  @media (max-width: 575px) {
    #footer .footer-top .footer-info {
      margin: -20px 0 30px 0;
    }
  }
  

  // ==========================  contact us ================================
  
  .contact .info-box {
    color: #0b2341;
    text-align: center;
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 20px 0 30px 0;
    background: #fff;
  }
  
  .contact .info-box i {
    font-size: 32px;
    color: #ed502e;
    border-radius: 50%;
    padding: 8px;
    border: 2px dotted #fbdad2;
  }
  
  .contact .info-box h3 {
    font-size: 20px;
    color: #777777;
    font-weight: 700;
    margin: 10px 0;
  }
  
  .contact .info-box p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .contact .php-email-form {
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 30px;
    background: #fff;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .error-message br + br {
    margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input, .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
  }
  
  .contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
    border-color: #ed502e;
  }
  
  .contact .php-email-form input {
    padding: 10px 15px;
  }
  
  .contact .php-email-form textarea {
    padding: 12px 15px;
  }
  
  .contact .php-email-form button[type="submit"] {
    background: #ed502e;
    border: 0;
    padding: 10px 30px;
    border-radius: 4px;
    color: #fff;
    transition: 0.4s;
  }
  
  .contact .php-email-form button[type="submit"]:hover {
    background: #ef6445;
  }
  
  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

  // ===========================  Gallery   ===========================

  .gallery .gallery-item {
    overflow: hidden;
    border-right: 3px solid #fff;
    border-bottom: 3px solid #fff;
  }
  
  .gallery .gallery-item img {
    transition: all ease-in-out 0.4s;
  }
  
  .gallery .gallery-item:hover img {
    transform: scale(1.1);
  }

  // =============================== Why Us ======================

  .why-us .box {
    padding: 50px 30px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    transition: all ease-in-out 0.3s;
    height: 100%;
  }
  
  .why-us .box span {
    display: block;
    font-size: 28px;
    font-weight: 700;
    color: #ef6445;
  }
  
  .why-us .box h4 {
    font-size: 24px;
    font-weight: 600;
    padding: 0;
    margin: 20px 0;
    color: #6c665c;
  }
  
  .why-us .box p {
    color: #aaaaaa;
    font-size: 15px;
    margin: 0;
    padding: 0;
  }
  
  .why-us .box:hover {
    background: #ef6445;
    padding: 30px 30px 70px 30px;
    box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
  }
  
  .why-us .box:hover span, .why-us .box:hover h4, .why-us .box:hover p {
    color: #fff;
  }
  
  //===========================   License  ========================

  .services .icon-box {
    
    img {

      
      width: 80%;
      height: 80%;
    }

  } 

  .services .icon-box:hover{
    transform: scale(1.1);
    transition:  0.5s;
    
    img {  
      box-shadow: 2px 0 35px 0 rgba(68, 88, 144, 0.15);
    }
    
  }

  // ==================== crousel ======================


  .carousel-inner .carousel-item {

      // width: 80%;
      background-color: rgba(214, 215, 216, 0.6);

  }

  // ========================     Our Clients  =====================
  .clients .clients-slider .swiper-slide img {
    opacity: 0.5;
    transition: 0.3s;
  }
  
  .clients .clients-slider .swiper-slide img:hover {
    opacity: 1;
  }
  
  .clients .clients-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .clients .clients-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #4154f1;
  }
  
  .clients .clients-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #4154f1;
  }

  // ========================== Testimonials ===========================

  .testimonials .testimonial-item {
    box-sizing: content-box;
    padding: 40px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    position: relative;
    background: #fff;
  }
  
  .testimonials .testimonial-item:hover {
    transition:  0.5s;
    transform: scale(1.04);
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  }
  
  .testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50px;
    border: 6px solid #fff;
    float: left;
    margin: 0 10px 0 0;
  }
  
  .testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #111;
  }
  
  .testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
  }
  
  .testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {
    color: #f8d1d3;
    font-size: 26px;
  }
  
  .testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
  }
  
  .testimonials .testimonial-item p {
    font-style: italic;
    margin: 15px 0 0 0;
    padding: 0;
  }

  // =======================  Our Team   ====================

  .team {
    background: #fff;
    padding: 60px 0;
  }
  
  .team .member {
    overflow: hidden;
    text-align: center;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    transition: 0.3s;
  }
  
  .team .member .member-img {
    position: relative;
    overflow: hidden;
  }
  
  .team .member .member-img:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: url(team-shape.svg) no-repeat center bottom;
    background-size: contain;
    z-index: 1;
  }
  
  .team .member .social {
    position: absolute;
    right: -100%;
    top: 30px;
    opacity: 0;
    border-radius: 4px;
    transition: 0.5s;
    background: rgba(255, 255, 255, 0.3);
    z-index: 2;
  }
  
  .team .member .social a {
    transition: color 0.3s;
    color: rgba(1, 41, 112, 0.5);
    margin: 15px 12px;
    display: block;
    line-height: 0;
    text-align: center;
  }
  
  .team .member .social a:hover {
    color: rgba(1, 41, 112, 0.8);
  }
  
  .team .member .social i {
    font-size: 18px;
  }
  
  .team .member .member-info {
    padding: 10px 15px 20px 15px;
  }
  
  .team .member .member-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
    color: #012970;
  }
  
  .team .member .member-info span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #aaaaaa;
  }
  
  .team .member .member-info p {
    font-style: italic;
    font-size: 14px;
    padding-top: 15px;
    line-height: 26px;
    color: #5e5e5e;
  }
  
  .team .member:hover {
    transform: scale(1.08);
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
  }
  
  .team .member:hover .social {
    right: 8px;
    opacity: 1;
  }

  // ============================  about ======================
  .about .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    padding-left: 28px;
    position: relative;
  }
  
  .about .content ul li + li {
    margin-top: 10px;
  }
  
  .about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #ed502e;
    line-height: 1;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  
  .about .content .btn-learn-more {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #ed502e;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #ed502e;
  }
  
  .about .content .btn-learn-more:hover {
    background: #ed502e;
    color: #fff;
    text-decoration: none;
  }
  
  /*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
  .counts {
    padding-top: 0;
  }
  
  .counts .count-box {
    box-shadow: -10px -5px 40px 0 rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 100%;
  }
  
  .counts .count-box i {
    display: block;
    font-size: 30px;
    color: #ed502e;
    float: left;
  }
  
  .counts .count-box span {
    font-size: 42px;
    line-height: 24px;
    display: block;
    font-weight: 700;
    color: #0b2341;
    margin-left: 50px;
  }
  
  .counts .count-box p {
    padding: 30px 0 0 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
  }
  
  .counts .count-box a {
    font-weight: 600;
    display: block;
    margin-top: 20px;
    color: #164682;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    transition: ease-in-out 0.3s;
  }
  
  .counts .count-box a:hover {
    color: #2169c4;
  }
  
  /*--------------------------------------------------------------
  # Why Us
  --------------------------------------------------------------*/
  .why-us {
    padding: 0;
  }
  
  .why-us .content {
    padding: 60px 100px 0 100px;
  }
  
  .why-us .content h3 {
    font-weight: 400;
    font-size: 34px;
    color: #123a6d;
  }
  
  .why-us .content h4 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 5px;
  }
  
  .why-us .content p {
    font-size: 15px;
    color: #05101e;
  }
  
  .why-us .video-box {
    background-image: url("./why-us.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 400px;
    position: relative;
  }
  
  .why-us .accordion-list {
    padding: 0 100px 60px 100px;
  }
  
  .why-us .accordion-list ul {
    padding: 0;
    list-style: none;
  }
  
  .why-us .accordion-list li + li {
    margin-top: 15px;
  }
  
  .why-us .accordion-list li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
  }
  
  .why-us .accordion-list a {
    text-decoration: none;
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding-right: 30px;
    outline: none;
    color: #0b2341;
    cursor: pointer;
  }
  
  .why-us .accordion-list span {
    color: #ed502e;
    font-weight: 600;
    font-size: 18px;
    padding-right: 10px;
  }
  
  .why-us .accordion-list i {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .why-us .accordion-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
  }
  
  .why-us .accordion-list .icon-show {
    display: none;
  }
  
  .why-us .accordion-list a.collapsed {
    color: #0b2341;
  }
  
  .why-us .accordion-list a.collapsed:hover {
    color: #ed502e;
  }
  
  .why-us .accordion-list a.collapsed .icon-show {
    display: inline-block;
  }
  
  .why-us .accordion-list a.collapsed .icon-close {
    display: none;
  }
  
  .why-us .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#ed502e 50%, rgba(237, 80, 46, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  
  .why-us .play-btn::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .why-us .play-btn::before {
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate-btn 2s;
    animation: pulsate-btn 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(237, 80, 46, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  
  .why-us .play-btn:hover::after {
    border-left: 15px solid #ed502e;
    transform: scale(20);
  }
  
  .why-us .play-btn:hover::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    -webkit-animation: none;
    animation: none;
    border-radius: 0;
  }
  
  @media (max-width: 1024px) {
    .why-us .content, .why-us .accordion-list {
      padding-left: 0;
      padding-right: 0;
    }
  }
  
  @media (max-width: 992px) {
    .why-us .content {
      padding-top: 30px;
    }
    .why-us .accordion-list {
      padding-bottom: 30px;
    }
  }
  
  @-webkit-keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  // =================== products ==================

  .products .product {

      text-align: center;
      background-color: #fefefe;
      
      // box-shadow: 0px 2px 30px 15px rgba(110, 123, 131, 0.1);
      .img{
        
        img {
           width: 250px;
           height: 350px;
           margin-bottom: 15px;
           transition: all ease-in-out 0.6s;
        }
      }

      h4 {
        font-weight: 600;
        margin-bottom: 15px;
        a{
          text-decoration: none;
          color: #ed502e;
        }
      }

      p{
        line-height: 24px;
        font-size: 14px;
        margin-bottom: 0;
      }

  }

  .products .product:hover {
    img {
      transform: scale(1.08);
      // transition: ease-in-out 0.8s;
  }
  
}

// =============================  Header   =========================

.header .social-links {
 
  margin-right: 15px;
}
.header .social-links a {
 
    font-size: 18px;
    display: inline-block;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    background: #ed502e;
}